import { useState } from "react";
import { API_BASE_URL } from "./Config";
import { useNavigate } from "react-router-dom";


export function SignInComponent() {


    const navigate = useNavigate();


    const [formData, setFormData] = useState({ username: "", password: "" });
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const handleSubmit = async (e) => {

        e.preventDefault();

        setErrorMessage('');
        setLoading(true);

        console.log(formData);

        const response = await fetch(`${API_BASE_URL}rcservice/portallogin`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                inputdata: {
                    username: formData.username,
                    password: formData.password,
                },
            }),
        });

        const data = await response.json();
        console.log(data["ResultData"], 'in signin time');

        if (data.Status) {

            setLoading(false);
            sessionStorage.setItem("userData", JSON.stringify(data["ResultData"][0]));
           navigate("/profile")

        } else {

            setLoading(false);
            setErrorMessage(data.Message);
        }
    };

    const handleChange = (e) => {
        setErrorMessage('');
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    return (
        <div data-kt-name="metronic" id="kt_body" class="app-blank app-blank bgi-size-cover bgi-position-center bgi-no-repeat">

            <div class="d-flex flex-column flex-root" id="kt_app_root">
                <style>
                    {`
          body {
            background-image: url('assets/media/auth/bg10.jpeg');
          }

          [data-theme="dark"] body {
            background-image: url('assets/media/auth/bg10-dark.jpeg');
          }
        `}
                </style>
                <div class="d-flex flex-column flex-lg-row flex-column-fluid">
                    <div class="d-flex flex-lg-row-fluid">
                        <div class="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
                            <img class="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" src="assets/media/auth/agency.png" alt="" />
                            <img class="theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" src="assets/media/auth/agency-dark.png" alt="" />
                            <h1 class="text-gray-800 fs-2qx fw-bold text-center mb-7">Fast, Efficient and Productive</h1>
                        </div>
                    </div>
                    <div class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
                        <div class="bg-body d-flex flex-center rounded-4 w-md-600px p-10">
                            <div class="w-md-400px">
                                <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" data-kt-redirect-url="../../demo1/dist/index.html" action="#" onSubmit={handleSubmit}>
                                    <div class="text-center mb-11">
                                        <h1 class="text-dark fw-bolder mb-3">Sign In</h1>
                                        <div class="text-gray-500 fw-semibold fs-6">Your Social Campaigns</div>
                                    </div>

                                    <div class="fv-row mb-8">
                                        <input  type="text"
                      name="username"
                      placeholder="User id"
                      autoComplete="off"
                      
                      value={formData.username}
                      onChange={handleChange}
                       autocomplete="off"
                                          class="form-control bg-transparent" />
                                    </div>
                                    <div class="fv-row mb-3">
                                        <input 
                                        type={isPasswordVisible ? "text" : "password"}
                                        name="password"
                                        placeholder="Password"
                                      
                                       
                                        value={formData.password}
                                        onChange={handleChange}
                                        autocomplete="off"
                                         class="form-control bg-transparent" />
                                    </div>
                                    <div className="fv-row mb-3  p-0 d-flex align-items-center">
                  <input
                    id="showpassword"
                    type="checkbox"
                    autoComplete="off"
                    checked={isPasswordVisible}
                    onChange={() =>
                      setIsPasswordVisible((prevState) => !prevState)
                    }
                  />
                  <label htmlFor="showpassword" className="mx-2">
                    Show Password
                  </label>
                </div>

                                    <div class="d-grid mb-10">
                                        <button type="submit" id="kt_sign_in_submit" class="btn btn-primary">
                                            
                                            <span class="indicator-progress">Please wait...
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                {loading ? "Signin......" : "Sign In"}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <script>var hostUrl = "assets/";</script>
            <script src="assets/plugins/global/plugins.bundle.js"></script>
            <script src="assets/js/scripts.bundle.js"></script>
            <script src="assets/js/custom/authentication/sign-in/general.js"></script>
        </div>
    )
}