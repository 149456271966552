import { useState } from "react";
import Pagination from "./Pagination";
import { Select } from "antd";
import { HeaderComponent } from "./header";
import { FooterComponent } from "./footer";



export function ManualAttandanceComponent(){

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
   
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return(
      <>
      <HeaderComponent></HeaderComponent>
        <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    ManualAttendance
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title row">
                    <div className="col-6 col-lg-3 d-flex flex-column align-items-start  my-1 mx-6 p-0">
                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="course"
                        >
                          Year
                        </label>
                        <Select
                          style={{ width: 140 }}
                          placeholder="Choose Year"
                          id="course"
                          optionFilterProp="label"
                          
                        >
                          <option>Select</option>
															<option value="all">2022</option>
															<option value="active">2023</option>
															<option value="locked">2024</option>
                        </Select>
                      </div>

                      <div className="col-6 col-lg-3 d-flex flex-column align-items-start  my-1 mx-6 p-0">
                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="course"
                        >
                          Status
                        </label>
                        <Select
                          style={{ width: 140 }}
                          placeholder="Choose Status"
                          id="course"
                          optionFilterProp="label"
                          
                        >
                          <option>Select</option>
															<option value="all">All</option>
															<option value="active">Pending</option>
															<option value="locked">Rejected</option>
                              <option value="locked">Approved</option>
                        </Select>
                      </div>

                     

                      <div className="col-6 col-lg-3 mt-9 mx-2 w-100px">
                        <button
                          type="button"
                          className="btn btn-primary d-flex flex-center h-35px h-lg-40px"
                         
                        >
                          Search
                          {/* <span className="d-none d-sm-inline ps-2">New</span> */}
                        </button>
                      </div>
                    </div>
                    
                  </div>
                  <div className="card-body pt-0">
                    <div className="table-responsive">
                      <table
                        className="table align-middle table-row-dashed fs-6 gy-5"
                        id="kt_customers_table"
                      >
                        <thead>
                          <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                            <th className="w-10px pe-2">
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                SL.NO
                              </div>
                            </th>
                            <th className="min-w-125px">Employee</th>
                            <th className="min-w-125px">Actual Shift</th>
                            <th className="min-w-125px">
                              In Time
                            </th>
                            <th className="min-w-125px">Out Time</th>
                            <th className="min-w-125px">In Status</th>
                            <th className="min-w-125px">Out Status</th>
                            <th className="min-w-125px">Action</th>
                           
                          </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                        
                            <tr >
                              <td>
                                <div className="form-check form-check-sm form-check-custom form-check-solid">
                                 
                                </div>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            
                              <td></td>
                              <td></td>
                            </tr>
                       
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      itemsPerPage={itemsPerPage}
                      
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent></FooterComponent>
      </>
    )
}