import { useEffect, useState } from "react";
import Pagination from "./Pagination";
import { Select } from "antd";
import { API_BASE_URL } from "./Config";
import { HeaderComponent } from "./header";
import { FooterComponent } from "./footer";
import Swal from "sweetalert2";



export function LeaveApplicationComponent() {


  const [showActionPopup, setShowActionPopup] = useState(false);
  const [storedUserData, setStoredUserData] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [lvTypes, setLvTypes] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState(null);

  const [dataLoading, setDataLoading] = useState(false);
  const [leavesData, setLeavesData] = useState([]);

  const [year, setYear] = useState("");
  const [status, setStatus] = useState("");

  const [halfDay, setHalfDay] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [comments, setComments] = useState("");
  const [regId, setRegId] = useState("");
  const [regIdFilter, setFilterRegId] = useState("");
  const [leaveCategory, setLeaveCategory] = useState("");

  // const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const [selectedLeaveType, setSelectedLeaveType] = useState(null);

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setStoredUserData(userData);
    } else {
      console.log("User data not found in sessionStorage");
    }
  }, []);


  const statusOptions = [
    { value: "", label: "All" },
    { value: "1", label: "Pending" },
    { value: "2", label: "Approved" },
    { value: "3", label: "Rejected" },
  ];

  const yearOptions = [
    { value: "2024", label: "2024" },
    { value: "2023", label: "2023" },
    { value: "2022", label: "2022" },
  ];

  useEffect(() => {
    if (storedUserData && storedUserData.RegId && storedUserData.isManager) {

      const fetchTeamMembers = async () => {
        try {
          const response = await fetch(
            `${API_BASE_URL}team/getmyteam?regid=${storedUserData.RegId}`
          );

          const data = await response.json();

          const leaveTypeResponse = await fetch(
            `${API_BASE_URL}rcservice/getleavetype?superid=${storedUserData.SuperId}`
          );

          const LvTypedata = await leaveTypeResponse.json();

          setTeamMembers(data["ResultData"]);
          setLvTypes(LvTypedata["ResultData"]);

        } catch (error) {
          console.error("Error fetching team members:", error);
        }
      };

      fetchTeamMembers();
    }
  }, [storedUserData && storedUserData.RegId]);

  const calculateLeaveCount = (startDate, endDate, halfDay) => {
    // Parse start and end dates
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    // Calculate the difference in days
    const timeDifference = endDateObj - startDateObj;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    let leaveCount = 0;

    if ((startDate === endDate) && halfDay) {
      // If start date and end date are the same, it's a one-day leave
      leaveCount = 0.5;
    }
    else if (startDate === endDate) {
      leaveCount = 1;
    }
    else {
      // If it's not a one-day leave, check if it's a half-day leave
      if (halfDay) {
        // If it's a half-day leave, add 0.5 to the leave count
        leaveCount = daysDifference + 0.5;
      } else {
        // If it's not a half-day leave, leave count is the number of full days
        leaveCount = daysDifference;
      }
    }
    console.log(leaveCount, 'final count');

    return leaveCount;
  };

  const handleLeaveTypeChange = (selectedOption) => {
    setSelectedLeaveType(selectedOption);
    // Here you can access the selected type's ID
    if (selectedOption) {
      console.log("Selected leave type ID:", selectedOption);
      setLeaveCategory(selectedOption);
    }
  };

  const handleEmpChange = (selectedOption) => {
    setRegId(selectedOption);
  };

  const handleEmpChangeFilter = (selectedOption) => {
    setFilterRegId(selectedOption);
  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    if (storedUserData.RegId && regIdFilter || status || year) {
      setDataLoading(true);
      console.log("Year:", year)
      try {
        const response = await fetch(
          `${API_BASE_URL}team/getmyteamleaves?regid=${storedUserData.RegId}&emplregid=${regIdFilter}&status=${status}&year=${year}`
        );
        if (response.ok) {

          const data = await response.json();
          console.log("data:", data)

          if (data.Status) {
            setDataLoading(false);
            const sortedLeavesData = data["ResultData"].sort((a, b) => {
              // Convert the dates to Date objects
              const dateA = new Date(a.StartDt);
              const dateB = new Date(b.StartDt);

              // Sort in descending order based on StartDt
              return dateB - dateA;
            });

            setLeavesData(sortedLeavesData);
          }

        } else {

          setDataLoading(false);
          console.error("Failed to fetch leaves data:", response.statusText);
        }
      } catch (error) {

        setDataLoading(false);
        console.error("Error fetching leaves data:", error.message);
      }
    }
  };

  const handleLeaveSubmit = async (e) => {
    e.preventDefault();
    const leaveCount = calculateLeaveCount(startDate, endDate, halfDay);

    if (!startDate || !endDate || !storedUserData.RegId || !comments) {

      alert('Select required fileds.')
    }
    else {
      setLoading(true);

      const leaveData = {
        inputdata: {
          superid: storedUserData.SuperId,
          regid: storedUserData.RegId,
          lvtypeid: selectedLeaveType,
          halfday: halfDay ? 1 : 0,
          startdate: startDate,
          enddate: endDate,
          leavecount: leaveCount,
          comments: comments,
          createdby: storedUserData.RegId,
        },
      };

      try {
        // Call the API to submit leave
        const response = await fetch(`${API_BASE_URL}rcservice/saveleave`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ inputdata: leaveData["inputdata"] }),
        });

        //} Handle the response accordingly
        const result = await response.json();
        if (result.Status) {
          setLoading(false);
          // setSubmitBtnLoading(false);

          Swal.fire({
            title: "Success",
            text: `${result.Message}`,
            icon: "success",
          }).then((result) => {
            // Reload the page after the user acknowledges the success message
            if (result.isConfirmed || result.isDismissed) {
              window.location.reload();
            }
          });
        } else {
          setLoading(false);
          // Show error message
          Swal.fire({
            title: "Error",
            text: `${result.Message}`,
            icon: "error",
          });
        }

        // You may want to handle success or error scenarios here
      } catch (error) {
        setLoading(false);
        console.error("Error submitting leave:", error);
      }
    }
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = leavesData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(leavesData.length / itemsPerPage);
  const maxVisibleButtons = 6; // Maximum number of visible buttons excluding previous and next buttons
  const totalButtons = Math.min(maxVisibleButtons, totalPages); // Total number of buttons to display
  const maxPageToShow = Math.min(
    currentPage + Math.floor(maxVisibleButtons / 2),
    totalPages
  ); // Max page number to display
  const minPageToShow = Math.max(1, maxPageToShow - maxVisibleButtons + 1);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const getClassnameForStatus = (status) => {
    switch (status) {
      case 1: // Pending
        return "text-warning";
      case 2: // Accepted
        return "text-success";
      case 3: // Rejected
        return "text-danger";
      default:
        return "";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Pending";
      case 2:
        return "Accepted";
      case 3:
        return "Rejected";
      default:
        return "Unknown";
    }
  };

  const handleActionButtonClick = (attendance) => {
    setSelectedLeave(attendance);
    setShowActionPopup(true);
  };

  const handleActionClosePopup = () => {
    setShowActionPopup(false);
  };

  const [selectedStatus, setSelectedStatus] = useState(""); // State variable to store selected status
  const [selectedReason, setSelectedReason] = useState(""); // State variable to store selected reason

  // Function to handle status change
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  // Function to handle reason change
  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  const handleSaveAction = async () => {
    if (!selectedLeave) return;

    try {
      console.log(selectedStatus, selectedLeave.Id, selectedLeave.RegId, selectedReason, 'values')
      const response = await fetch(
        "https://rollcallapis.azurewebsites.net/rcservice/updateleave",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inputdata: {
              status: selectedStatus,
              leaveid: selectedLeave.Id,
              regid: selectedLeave.RegId,
              comments: selectedReason,
            },
          }),
        }
      );
      const data = await response.json()
      console.log(data, 'datatda getting')
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setShowActionPopup(false);
    } catch (error) {
      console.error("Error updating leave:", error);
    }
  };

  if (storedUserData.isManager === false) {
    return (
      <div className="text-center">
        <h2>You do not have access to this page.</h2>
      </div>
    );
  }
  return (
    <>
      <HeaderComponent></HeaderComponent>

      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Leave Application
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title row">
                      <form className="d-flex flex-wrap" onSubmit={handleSubmit}>
                        <div className="col-6 col-lg-3 d-flex flex-column align-items-start  my-1 mx-2 p-0">
                          <label
                            className="fs-6 fw-semibold mt-2 mb-3"
                            htmlFor="course"
                          >
                            Year
                          </label>
                          <Select
                            style={{ width: 140 }}
                            placeholder="Choose Year"
                            id="course"
                            optionFilterProp="label"
                            options={yearOptions}
                            onChange={(selectedOption) => setYear(selectedOption.value)}

                          >

                          </Select>
                        </div>

                        <div className="col-6 col-lg-3 d-flex flex-column align-items-start  my-1 mx-2 p-0">
                          <label
                            className="fs-6 fw-semibold mt-2 mb-3"
                            htmlFor="course"
                          >
                            Status
                          </label>
                          <Select
                            style={{ width: 140 }}
                            placeholder="Choose Status"
                            id="course"
                            optionFilterProp="label"
                            options={statusOptions}
                            onChange={(selectedOption) => setStatus(selectedOption.value)}

                          >

                          </Select>
                        </div>

                        <div className="col-6 col-lg-3 d-flex flex-column align-items-start  my-1 mx-2 p-0">
                          <label
                            className="fs-6 fw-semibold mt-2 mb-3"
                            htmlFor="course"
                          >
                            Employees
                          </label>
                          <Select
                            style={{ width: 140 }}
                            placeholder="Choose Emp"
                            id="course"
                            optionFilterProp="label"
                            name="employee"
                            value={regIdFilter}
                            onChange={handleEmpChangeFilter}
                            options={teamMembers.map((option) => ({
                              value: option.RegId,
                              label: option.UserName,
                            }))}

                          >

                          </Select>
                        </div>



                        <div className="col-6 col-lg-3 d-flex flex-column align-items-start  my-1 mx-2 p-0">
                          <button
                            type="submit"
                            className="btn btn-primary d-flex flex-center h-35px h-lg-40px"

                          >
                            Search
                            {/* <span className="d-none d-sm-inline ps-2">New</span> */}
                          </button>
                        </div>
                      </form>

                    </div>
                    <div className="toolbar  " id="kt_toolbar">

                      <div
                        id="kt_toolbar_container"

                        className="app-container container-xxl d-flex flex-stack flex-wrap"
                      >

                        <div className="page-title d-flex flex-column">

                        </div>
                        <div>
                          {/* Button to trigger the off-canvas */}
                          <button
                            className="btn btn-primary"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#applyLeaveOffCanvas"
                            aria-controls="applyLeaveOffCanvas"
                          >
                            Apply Leave
                          </button>

                          {/* Off-canvas component */}
                          <div
                            className="offcanvas offcanvas-start"
                            tabIndex="-1"
                            id="applyLeaveOffCanvas"
                            aria-labelledby="offcanvasExampleLabel"
                          >
                            <div className="offcanvas-header bg-secondary">
                              <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                                Apply Leave
                              </h5>
                              <button
                                type="button"
                                className="btn-close text-reset"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="offcanvas-body p-0">
                              <form className="row p-7" onSubmit={handleLeaveSubmit}>
                                <div className="col-6 d-flex flex-column dropdown mt-3 ">
                                  <label
                                    htmlFor="gender"
                                    className="required form-label mb-3"
                                  >
                                    Emp Type
                                  </label>
                                  <Select
                                    style={{ width: 160 }}
                                    // placeholder="Gender"
                                    placeholder="Choose Type"
                                    value={regId}
                                    onChange={handleEmpChange}
                                    options={teamMembers.map((option) => ({
                                      value: option.RegId,
                                      label: option.UserName,
                                    }))}

                                  />
                                </div>
                                <div className="col-6 d-flex flex-column dropdown mt-3 ">
                                  <label
                                    htmlFor="gender"
                                    className="required form-label mb-3"
                                  >
                                    Leave Type
                                  </label>
                                  <Select
  style={{ width: 160 }}
  placeholder="Choose Type"
  value={selectedLeaveType}
  onChange={handleLeaveTypeChange}
  options={lvTypes.map((option) => {
    console.log('Option in map:', option);  // Log each option to check data
    return {
      value: option.LvTypeId,  // Set value as ID
      label: option.LvType,    // Set label as the type name
    };
  })}
/>
                                </div>
                                <div className="col-6 d-flex flex-column dropdown mt-3">
                                  <label
                                    htmlFor="date"
                                    className="required form-label mb-3"
                                  >
                                    Start Date
                                  </label>
                                  <input
                                    type="date"
                                    style={{ width: 160 }}
                                    className="form-control form-control-sm form-control-solid"
                                    placeholder=""
                                    id="date"
                                    name="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    required

                                  />
                                </div>

                                <div className="col-6 d-flex flex-column dropdown mt-3">
                                  <label
                                    htmlFor="date"
                                    className="required form-label mb-3"
                                  >
                                    End Date
                                  </label>
                                  <input
                                    type="date"
                                    style={{ width: 160 }}
                                    className="form-control form-control-sm form-control-solid"
                                    placeholder=""
                                    id="date"
                                    name="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}


                                  />
                                </div>
                                <div className="col-6 d-flex flex-column dropdown mt-3">
                                  <label htmlFor="halfDayLeave" className="required form-label mb-3">
                                    Half Day Leave
                                  </label>
                                  <input
                                    type="checkbox"
                                    id="halfDayCheckbox"
                                    name="half_day_leave"
                                    className="form-check-input"
                                    checked={halfDay}
                                    onChange={(e) => setHalfDay(e.target.checked)}
                                  />
                                </div>
                                <div className="col-12 mb-5 fv-row">
                                  <label
                                    htmlFor="address"
                                    className="required form-label mb-3"
                                  >
                                    reason
                                  </label>

                                  <textarea
                                    required
                                    className="form-control form-control-sm form-control-solid"
                                    placeholder=""
                                    id="address"
                                    name="comment"
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}

                                  />
                                </div>

                                <div>
                                  <button type="submit" className="btn btn-primary">
                                    save
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>

                  </div>
                  <div className="card-body pt-0">
                    <div className="table-responsive">
                      <table
                        className="table align-middle table-row-dashed fs-6 gy-5"
                        id="kt_customers_table"
                      >
                        <thead>
                          <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                            <th className="w-10px pe-2">
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                SL.NO
                              </div>
                            </th>
                            <th className="min-w-125px">Card Id</th>
                            <th className="min-w-125px">Employe Name</th>
                            <th className="min-w-125px">Leave Type</th>
                            <th className="min-w-125px"> Start Date</th>
                            <th className="min-w-125px">End Date</th>

                            <th className="min-w-125px">Count</th>
                            <th className="min-w-125px">Reason</th>
                            <th className="min-w-125px">Status</th>
                            <th className="min-w-125px">Action</th>

                          </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                          {dataLoading ? (
                            <tr>
                              <td colSpan={12} className="text-center">
                                <p className="loader"></p>
                              </td>
                            </tr>
                          ) : currentItems.length > 0 ? (
                            currentItems.map((attendance, index) => (
                              <tr key={index}>
                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td>{attendance.CardId}</td>
                                <td>{attendance.UserName}</td>
                                <td>{attendance.LvType}</td>
                                <td>{formatDate(attendance.StartDt)}</td>
                                <td>{formatDate(attendance.EndDt)}</td>
                                <td>{attendance.LeaveCount}</td>
                                <td className={getClassnameForStatus(attendance.status)}>
                                  {getStatusText(attendance.status)}
                                </td>
                                <td>{attendance.Comments ? attendance.Comments : "N/A"}</td>
                                <td>
                                  <i
                                    className="fa-solid fa-street-view text-primary"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleActionButtonClick(attendance)} // Pass the attendance object to handleActionButtonClick
                                  ></i>
                                </td>

                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={12}>
                                No data available.
                              </td>
                            </tr>
                          )}

                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      itemsPerPage={itemsPerPage}

                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent></FooterComponent>
    </>
  )
}