import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export function HeaderComponent() {
    const [storedUserData, setStoredUserData] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
          const userData = JSON.parse(userDataString);
          setStoredUserData(userData);
        } else {
          console.log("User data not found in sessionStorage");
        }
      }, []);


    const handleLogout = () => {
        sessionStorage.removeItem('userData'); 
        navigate('/'); 
      };

    return (

        <div
            data-kt-name="metronic"
            id="kt_app_body"
            data-kt-app-layout="dark-sidebar"
            data-kt-app-header-fixed="true"
            data-kt-app-sidebar-enabled="true"
            data-kt-app-sidebar-fixed="true"
            data-kt-app-sidebar-hoverable="true"
            data-kt-app-sidebar-push-header="true"
            data-kt-app-sidebar-push-toolbar="true"
            data-kt-app-sidebar-push-footer="true"
            data-kt-app-toolbar-enabled="true"
            className="app-default"
        >
            <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                <div className="app-page flex-column flex-column-fluid" id="kt_app_page">

                    {/* Full-width Header */}
                    <div className="bg-white d-flex align-items-center justify-content-between" style={{ width: "100%", padding: "0", margin: "0" }}>

                        {/* Left side: Logo */}
                        <div className="d-flex align-items-center" style={{ flex: 1 }}>
                            <img
                                alt="Logo"
                                src="assets/images/rollcall.jpg"
                                style={{ height: "66px", width: "auto", maxWidth: "100%" }}
                            />
                        </div>

                        {/* Right side: User avatar and icons */}
                        <div className="d-flex align-items-center">
                            <div className="app-navbar-item ms-1 ms-lg-3">
                                {/* Optional search icon */}
                                {/* <div className="btn btn-icon btn-custom w-35px h-35px w-md-40px h-md-40px">
                                    <span className="svg-icon svg-icon-1">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor" />
                                            <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor" />
                                            <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor" />
                                            <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor" />
                                        </svg>
                                    </span>
                                </div> */}
                                
                            </div>
                            {/* User Avatar */}
                            <div className="d-flex align-items-center ms-2 ms-lg-4">
          <a
              className="btn btn-flex flex-center btn-primary align-self-center px-0 px-md-3 h-30px w-30px w-md-auto h-lg-40px ms-2 ms-lg-4"
            // data-bs-toggle="modal"
            // data-bs-target="#kt_modal_invite_friends"
            onClick={handleLogout}
            >
              <i className="fa-solid fa-right-from-bracket"></i>
              <span className="d-none d-md-inline ms-2">Logout</span>
              
            </a>
          </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

