



import { useEffect, useState } from "react";
import Pagination from "./Pagination";
import { Select } from "antd";
import { API_BASE_URL } from "./Config";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { FooterComponent } from "./footer";
import { HeaderComponent } from "./header";



export function TeamMumbersComponent(){

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    
   
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const [storedUserData, setStoredUserData] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);

    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
            const userData = JSON.parse(userDataString);
            console.log(userData, 'user data data');
            setStoredUserData(userData);
        } else {
            console.log("User data not found in sessionStorage");
        }
    }, []);
    const currentItems = teamMembers.slice(indexOfFirstItem, indexOfLastItem);

    const handleDownloadExcel = () => {
      if (teamMembers.length === 0) {
      return; // Don't download if there is no data
      }

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Convert your data to worksheet format
      const wsData = teamMembers.map((item) => ({
          "Employee Name": item.UserName,
          "Employee Code": item.RegId,
          "Badge": item.Badge,
          "Date Of Joining": item.DateOfJoining,
          "Email": item.EmailId,
          "Designation": item.Designation,
      }));

      const ws = XLSX.utils.json_to_sheet(wsData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "TeamMembers");

      // Save the workbook to a file
      XLSX.writeFile(wb, "TeamMembers.xlsx");
  };

  const generatePDF = () => {
      const doc = new jsPDF();

      // Set properties for PDF
      doc.setProperties({
          title: "Team Data",
          author: "Your Name",
          creator: "Your Application",
      });

      // Add data to PDF
      const tableData = []; // Your data array here

      // Add a header row
      const headers = [
      "Employee Name",
      "Employee Code",
      "Badge",
      "Date Of Joining",
      "Email",
      "Designation",
      ];
      tableData.push(headers);

      // Add rows
      teamMembers.forEach((item) => {
      const row = [];
      row.push(
          item.UserName,
          item.RegId,
          item.Badge,
          item.DateOfJoining,
          item.EmailId,
          item.Designation
      );
      tableData.push(row);
      });

      // Generate table
      doc.autoTable({
      head: [headers],
      body: tableData.slice(1),
      });

      // Save the PDF
      doc.save("TeammMebers.pdf");
  };

  const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
    
      return `${day}-${month}-${year}`;
    };
    
    useEffect(() => {
        if (storedUserData && storedUserData.RegId) { // Check if storedUserData and RegId are defined
            const fetchTeamMembers = async () => {
                setDataLoading(true);
                console.log(storedUserData.RegId, 'reg id data data');
                try {
                    const response = await fetch(
                        `${API_BASE_URL}team/getmyteam?regid=${storedUserData.RegId}`
                    );
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    const data = await response.json();
                    console.log(data, 'data data data');
                    setDataLoading(false);
    
                    setTeamMembers(data['ResultData']);
                } catch (error) {
                    setDataLoading(false);
                    console.error("Error fetching team members:", error);
                }
            };
    
            fetchTeamMembers();
        }
    }, [storedUserData && storedUserData.RegId]);

    return(
      <>
      <HeaderComponent></HeaderComponent>
        <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Leaves
                  </h1>
                </div>
              </div>
            </div>
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title row">

                    <div
                        className="d-flex justify-content-end pt-8"
                        data-kt-customer-table-toolbar="base"
                      >
                        <button
                          type="button"
                          className="btn btn-light-primary me-3 px-4"
                         onClick={generatePDF}
                          data-bs-target="#kt_customers_export_modal"
                        >
                          <i className="fa-solid fa-file-pdf fs-2"></i>
                        </button>
                     
                        <button
                          type="button"
                          className="btn btn-light-primary me-3 px-4"
                         onClick={handleDownloadExcel}
                          data-bs-target="#kt_customers_export_modal"
                        >
                          <i className="fa-solid fa-file-excel fs-2"></i>
                        </button>
                      </div>
                    {/* <div className="col-6 col-lg-3 d-flex flex-column align-items-start  my-1 mx-6 p-0">

                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="course"
                        >
                          Year
                        </label>
                        <Select
                          style={{ width: 140 }}
                          placeholder="Choose Year"
                          id="course"
                          optionFilterProp="label"
                          
                        >
                          <option>Select</option>
															<option value="all">2022</option>
															<option value="active">2023</option>
															<option value="locked">2024</option>
                        </Select>
                      </div>

                      <div className="col-6 col-lg-3 d-flex flex-column align-items-start  my-1 mx-6 p-0">
                        <label
                          className="fs-6 fw-semibold mt-2 mb-3"
                          htmlFor="course"
                        >
                          Status
                        </label>
                        <Select
                          style={{ width: 140 }}
                          placeholder="Choose Status"
                          id="course"
                          optionFilterProp="label"
                          
                        >
                          <option>Select</option>
															<option value="all">All</option>
															<option value="active">Pending</option>
															<option value="locked">Rejected</option>
                              <option value="locked">Approved</option>
                        </Select>
                      </div>

                     

                      <div className="col-6 col-lg-3 mt-9 mx-2 w-100px">
                        <button
                          type="button"
                          className="btn btn-primary d-flex flex-center h-35px h-lg-40px"
                         
                        >
                          Search
                           <span className="d-none d-sm-inline ps-2">New</span> 
                        </button>
                      </div> */}
                    </div>
                    
                  </div>
                  <div className="card-body pt-0">
                    <div className="table-responsive">
                      <table
                        className="table align-middle table-row-dashed fs-6 gy-5"
                        id="kt_customers_table"
                      >
                        <thead>
                          <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                            <th className="w-10px pe-2">
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                SL.NO
                              </div>
                            </th>
                            <th className="min-w-125px">Badge</th>
                            <th className="min-w-125px">Employe Id</th>
                            <th className="min-w-125px">
                              Employe Name
                            </th>
                            <th className="min-w-125px">Date of Joining</th>
                            <th className="min-w-125px">Email</th>
                            <th className="min-w-125px">Designation</th>
                           
                           
                          </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                        
                        {dataLoading ? (
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            <p className="loader"></p>
                                        </td>
                                    </tr>
                                ) : currentItems.length === 0 ? (
                                    <tr>
                                        <td className="text-center" colSpan="7">
                                            No data available
                                        </td>
                                    </tr>
                                ) : (
                                    currentItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                            <td>{item.Badge}</td>
                                            <td>{item.RegId}</td>
                                            <td>{item.UserName}</td>
                                            <td>{formatDate(item.DateOfJoining)}</td>
                                            <td>{item.EmailId}</td>
                                            <td>{item.Designation}</td>
                                        </tr>
                                    ))
                                )}
                       
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      itemsPerPage={itemsPerPage}
                      totalItems={teamMembers.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent></FooterComponent>
      </>
    )
}