import { useEffect, useState } from "react";
import Pagination from "./Pagination";
import { Select } from "antd";
import { API_BASE_URL } from "./Config";
import { HeaderComponent } from "./header";
import { FooterComponent } from "./footer";

export function BulkAttandanceComponent() {
  const [storedUserData, setStoredUserData] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamAttendance, setTeamAttendance] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
  const itemsPerPage = 8;

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setStoredUserData(userData);
    } else {
      console.log("User data not found in sessionStorage");
    }
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    if (storedUserData.RegId && storedUserData.isManager) {
      const fetchTeamMembers = async () => {
        try {
          setLoading(true); // Start loading
          const response = await fetch(
            `${API_BASE_URL}team/getmyteam?regid=${storedUserData.RegId}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          setTeamMembers(data['ResultData']);
        } catch (error) {
          console.error("Error fetching team members:", error);
        } finally {
          setLoading(false); // Stop loading
        }
      };
      fetchTeamMembers();
    }
  }, [storedUserData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const startDate = e.target.elements.startDate.value;
    const endDate = e.target.elements.endDate.value;
    const employeeId = selectedEmployeeId;

    if (!startDate || !endDate || !employeeId) {
      console.log("Please fill all fields");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}team/getmyteamattendance?regid=${storedUserData.RegId}&emplregid=${employeeId}&stdate=${startDate}&enddate=${endDate}`);
      if (response.ok) {
        const data = await response.json();
        if (data.Status) {
          setTeamAttendance(data['ResultData']);
          setMessage('');
        } else {
          setTeamAttendance([]);
          setMessage('No Data Available..');
        }
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = teamAttendance.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (!storedUserData.isManager ===false) {
    return (
      <div className="text-center">
        <h2>You do not have access to this page.</h2>
      </div>
    );
  }

  return (
    <>
      <HeaderComponent />
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
              <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
                <div className="page-title d-flex flex-column">
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                    Bulk Attendance
                  </h1>
                </div>
              </div>
            </div>
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
              <div className="content flex-row-fluid pt-1" id="kt_content">
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title row">
                      <form className="d-flex flex-wrap" onSubmit={handleSubmit}>
                        <div className="col-6 col-lg-3 d-flex flex-column align-items-start my-1 mx-6 p-0">
                          <label className="fs-6 fw-semibold mt-2 mb-3" htmlFor="start_date">Start Date</label>
                          <input type="date" id="start_date" className="form-control form-control-solid w-200px ps-3" name="startDate"></input>
                        </div>
                        <div className="col-6 col-lg-3 d-flex flex-column align-items-start my-1 mx-6 p-0">
                          <label className="fs-6 fw-semibold mt-2 mb-3" htmlFor="end_date">End Date</label>
                          <input type="date" id="end_date" className="form-control form-control-solid w-200px ps-3" name="endDate"></input>
                        </div>
                        <div className="col-6 col-lg-3 d-flex flex-column align-items-start my-1 mx-6 p-0">
                          <label className="fs-6 fw-semibold mt-2 mb-3" htmlFor="employee">Employee</label>
                          <Select
                            style={{ width: 140 }}
                            placeholder="Choose Emp"
                            options={teamMembers.map(option => ({
                              value: option.RegId,
                              label: option.UserName,
                            }))}
                            onChange={(value) => setSelectedEmployeeId(value)}
                          />
                        </div>
                        <div className="col-6 col-lg-3 mt-9 mx-2 w-100px">
                          <button type="submit" className="btn btn-primary d-flex flex-center h-35px h-lg-40px">
                            {loading ? 'Loading...' : 'Search'}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    {loading ? (
                      <div className="text-center">
                        <p className='loader'></p>
                        <span>Loading...</span>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_customers_table">
                          <thead>
                            <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                              <th className="w-10px pe-2">SL.NO</th>
                              <th className="min-w-125px">Employee Id</th>
                              <th className="min-w-125px">Employee Name</th>
                              <th className="min-w-125px">Designation</th>
                              <th className="min-w-125px">Department</th>
                              <th className="min-w-125px">Shift</th>
                              <th className="min-w-125px">In Time</th>
                              <th className="min-w-125px">Out Time</th>
                              <th className="min-w-125px">In Status</th>
                              <th className="min-w-125px">Out Status</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600">
                            {currentItems.length > 0 ? (
                              currentItems.map((attendance, index) => (
                                <tr key={index}>
                                  <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                  <td>{attendance.RegId}</td>
                                  <td>{attendance.UserName}</td>
                                  <td>{attendance.Designation}</td>
                                  <td>{attendance.Dept}</td>
                                  <td>{formatDate(attendance.DateOfTransaction)}</td>
                                  <td>{attendance.ActualShiftName || 'N/A'}</td>
                                  <td>{attendance.FormatInTime || 'N/A'}</td>
                                  <td className={attendance.InStCode === "P" ? "text-success fw-semibold" : "text-danger fw-semibold"}>{attendance.InStCode || 'N/A'}</td>
                                  <td className={attendance.OutStCode === "P" ? "text-success fw-semibold" : "text-danger fw-semibold"}>{attendance.OutStCode || 'N/A'}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={10} className="text-center text-danger">{message}</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <Pagination
                          itemsPerPage={itemsPerPage}
                          totalItems={teamAttendance.length}
                          paginate={paginate}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <FooterComponent />
          </div>
        </div>
      </div>
    </>
  );
}
