import { useEffect, useState } from "react";
import { API_BASE_URL } from "./Config";

export function HomeComponent() {

    const [storedUserData, setStoredUserData] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [teamLeaves, setTeamLeaves] = useState([]);
    const [dates, setDates] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [attendanceData, setAttendanceData] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedActiveDate, setSelectedActiveDate] = useState(null);
    const [selectedCurentDate, setSelectedCurentDate] = useState(null);

    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
          const userData = JSON.parse(userDataString);
          setStoredUserData(userData);
        } else {
          console.log("User data not found in sessionStorage");
        }
      }, []);


      useEffect(() => {
        if (storedUserData && storedUserData.RegId) { 
          const fetchTeamLeaves = async () => {
            try {
                const response = await fetch(
                    `${API_BASE_URL}team/getmyteamleaves?regid=${storedUserData.RegId}&emplregid=&status=&year=`
                );
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
        
                // Filter the data where the status value is 1
                const filteredData = data['ResultData'].filter(item => item.status == 1);
        
                setTeamLeaves(filteredData);
            } catch (error) {
                console.error("Error fetching team members:", error);
            }
          };    
    
            const fetchHolidays = async () => {
                try {
                  // Fetch holidays data from the service
                  const response = await fetch(
                    `${API_BASE_URL}rcservice/getholidays?superid=${storedUserData.SuperId}`
                  );
                  const data = await response.json();
    
                  if (data["Status"]) {
                    const presentDate = new Date();
                    const totalHolidaysData = data['ResultData'];
                    // Filter future holidays
                    const futureHolidays = totalHolidaysData.filter((holiday) => {
                      const holidayDate = new Date(holiday.HolidayDt);
                      return holidayDate > presentDate;
                    });
    
                    // Sort future holidays by date in ascending order
                    futureHolidays.sort(
                      (a, b) => new Date(a.HolidayDt) - new Date(b.HolidayDt)
                    );
    
                    // Take the first two future holidays
                    const nextTwoHolidays = futureHolidays.slice(0, 3);
    
                    setHolidays(nextTwoHolidays);
                  } else {
                    console.error("Failed to fetch holidays:", response.statusText);
                  }
    
                } catch (error) {
                  console.error("Error fetching holidays:", error.message);
                }
            };
    
           
    
            fetchTeamLeaves();
            fetchHolidays();
            
        }
      }, [storedUserData && storedUserData.RegId]);


      useEffect(() => {
        const getCurrentDate = () => {
          const currentDate = new Date();
          const currentDateString = currentDate.toDateString();
          setSelectedCurentDate(currentDate.getDate());
    
          return {
            day: currentDateString.slice(0, 3),
            date: currentDate.getDate(),
            fullDate: currentDate,
          };
        };
    
        const getLastSixDays = () => {
          const currentDate = new Date();
          const lastSixDays = Array.from({ length: 10}, (_, index) => {
            const previousDate = new Date();
            previousDate.setDate(currentDate.getDate() - index - 1);
            return {
              day: previousDate.toDateString().slice(0, 3),
              date: previousDate.getDate(),
              fullDate: previousDate,
            };
          });
          return lastSixDays;
        };
    
        const lastSixDays = getLastSixDays();
        const currentDate = getCurrentDate();
        setDates([currentDate, ...lastSixDays]);
      }, []);

     

      useEffect(() => {
        const fetch7DaysData = async () => {
          // const superid = storedUserData.SuperId;
          if (storedUserData && storedUserData.SuperId && storedUserData.RegId) {
          try {
            const response = await fetch(
              `${API_BASE_URL}rcservice/getemplast7daysattendance?superid=${storedUserData.SuperId}&regid=${storedUserData.RegId}`
            );
            const data = await response.json();
    
            // Ensure that data.ResultData is an array or convert it to an array if necessary
            const attendanceDataArray = Array.isArray(data.ResultData)
              ? data.ResultData
              : [];
            setAttendanceData(attendanceDataArray);
          } catch (error) {
            console.error("Error fetching leave types:", error);
          }
        };
    
        fetch7DaysData();
      }
      }, [storedUserData.SuperId, storedUserData.RegId]);

      const handleDateSelect = (selectedDate) => {
        const formattedDate = selectedDate.fullDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        const [month, day, year] = formattedDate.split("/");
    
        // Creating the desired format: MM/DD/YYYY
        const desiredFormatDate = `${year}-${month}-${day}`;
        setSelectedDate(desiredFormatDate);
        setSelectedActiveDate(day);
    
        // Perform other actions with the formatted date
        const filteredData = attendanceData.filter(item => {
          // Convert item.Date to the desired format (MM/DD/YYYY)
          const formattedDate = item.Date.split('/').reverse().join('-');
          return formattedDate === desiredFormatDate;
      });
      
        setFilterData(filteredData);
      };

      useEffect(() => {
        // Update the current time every second
        const intervalId = setInterval(() => {
          setCurrentTime(new Date());
        }, 1000);
    
        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
      }, []);  

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
    
        return `${day}-${month}-${year}`;
      };
  

    return (
        <div className="app-container container-xxl">
            <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
                <div className="card h-md-100">
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">User Attendance</span>
                        </h3>
                    </div>
                    <div className="card-body pt-7 px-0">
                        <ul className="nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5">
                            
                        {dates
                    .slice()
                    .reverse()
                    .map((dayDate, index) => (
                      <li key={index} className="nav-item p-0 ms-0">
                                    <a
                                        className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger ${dayDate.date == selectedActiveDate ? "active" : ""} ${dayDate.date == selectedCurentDate ? "border btn-danger border-3" : ""}`}
                                        data-bs-toggle="tab"
                                        onClick={() => handleDateSelect(dayDate)}
                                    >
                                        <span className="fs-7 fw-semibold">{dayDate.day}</span>
                                        <span className="fs-6 fw-bold">{dayDate.date}</span>
                                    </a>
                                </li>
                            ))}
                        </ul>

                        
                        {filterData.ActualShift}
                                    <div className="d-flex justify-content-between align-items-center flex-grow-1 me-5 mb-2">
                                        <div className="text-gray-800 fw-semibold fs-3  " style={ { marginLeft:'20px'}}>
                                            IN : {filterData[0] ? <span className="text-success">{filterData[0].InTime}</span> : 'NA'} - OUT: {filterData[0] ? <span className="text-danger">{filterData[0].OutTime}</span> : 'NA'}
                                            <span className="text-gray-400 fw-semibold fs-7"></span>
                                        </div>
                                        <div className="text-gray-700 fw-semibold fs-2">
                                            Shift : <a className="text-success">{filterData[0] ? filterData[0].Status : 'NA'}</a>

                                        </div>
                                        <div className="text-gray-800 fw-semibold fs-3">
                                            Status : {filterData[0] ? <span className="fw-semibold">{filterData[0].ActualShift}</span> : 'NA'}
                                            <span className="text-gray-400 fw-semibold fs-7"></span>
                                        </div>
                                        <div className="text-gray-700 fw-semibold fs-2">
                                            Duration : {filterData[0] ? <span className="fw-semibold">{filterData[0].Duration}</span> : 'NA'}

                                        </div>
                                    </div>
                                    </div>

                               </div>
  
                            <br />
                            <div className="row gy-5 g-xl-10">
                                <div className="col-xl-4 col-lg-6">
                                    <div className="card card-flush h-xl-100">
                                        <div className="card-header pt-7">
                                            <h3 className="card-title align-items-start flex-column">
                                                <span className="card-label fw-bold text-dark">Upcoming Holidays</span>
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="hover-scroll-overlay-y pe-6 me-n6" style={{ height: "415px" }}>
                                                <div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">
                                                    <div className="d-flex flex-stack mb-3">
                                                        <div className="me-3">
                                                            {/* <img src="assets/media/stock/ecommerce/210.gif" className="w-50px ms-n1 me-1" alt="" />
                                                            <a href="#" className="text-gray-800 text-hover-primary fw-bold">Elephant 1802</a> */}
                                                        </div>
                                                        <div className="m-0">
                                                            {/* <button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end">
                                                                <span className="svg-icon svg-icon-1">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor" />
                                                                        <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor" />
                                                                        <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor" />
                                                                        <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor" />
                                                                    </svg>
                                                                </span>
                                                            </button> */}
                                                        </div>
                                                    </div>
                                                    {holidays && holidays.length > 0 ? (
  holidays.map((item, index) => (
    <div className="d-flex flex-stack" key={index}>
      <span className="text-gray-400 fw-bold">
        Date:
        <a href="#" className="text-gray-800 text-hover-primary fw-bold">{item.HolidayDt}</a>
      </span>
      <span className="badge badge-light-info">{item.Reason}</span>
    </div>
  ))
) : (
  <div className="d-flex flex-stack">
    <span className="text-gray-400 fw-bold">
      No holidays found.
    </span>
  </div>
)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-6">
                                    <div className="card card-flush h-xl-100">
                                        <div className="card-header pt-7">
                                            <h3 className="card-title align-items-start flex-column">
                                                <span className="card-label fw-bold text-dark">Team Pending Leaves</span>
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table align-middle table-row-dashed fs-6 gy-3">
                                                    <thead>
                                                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                                            <th className="min-w-100px">S.No</th>
                                                            <th className="text-end pe-3 min-w-100px">Name</th>
                                                            <th className="text-end pe-3 min-w-150px">Date</th>
                                                            <th className="text-end pe-3 min-w-100px">Leave Type</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="fw-bold text-gray-600">
                                                    {teamLeaves.length > 0 ? 
                        teamLeaves.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.UserName}</td>
                            <td>{formatDate(item.StartDt)}</td>
                            <td>{item.LvType}</td>
                          </tr>
                        ))
                      :
                      <tr className="text-center fw-semibold">
                        <td colSpan={12}>No Leaves in pending.</td>
                      </tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                    );
}
