import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ProfileComponent } from './components/Profile';
import { HomeComponent } from './components/Home';
import { SignInComponent } from './components/Signin';
import { AttendenceComponent } from './components/Attendance';
import { LeavesComponent } from './components/Leaves';
import { LeaveSummaryComponent } from './components/LeaveSummary';
import { HeaderComponent } from './components/header';
import { FooterComponent } from './components/footer';
import { TeamMumbersComponent } from './components/TeamMembers';
import { MonthlyAttandanceComponent } from './components/MonthlyAttendance';
import { ManualAttandanceComponent } from './components/ManualAttendance';
import { BulkAttandanceComponent } from './components/BulkAttendance';
import { LeaveApplicationComponent } from './components/LeaveApplication';
import ProtectedRoute from './components/protectedroute';

function App() {
  return (
    <div className="App">
     <Router>
        <Routes>
          <Route path="/" element={<SignInComponent />} />
          <Route path="/profile" element={<ProtectedRoute>{""}<ProfileComponent />{""}</ProtectedRoute>} />
          <Route path="/Attendance" element={<AttendenceComponent />} />
          <Route path="/Leaves" element={<LeavesComponent />} />
          <Route path="/LeaveSummary" element={<LeaveSummaryComponent />} />
          <Route path="/Home" element={<HomeComponent />} />
          <Route path="/Header" element={<HeaderComponent />} />
          <Route path="/Footer" element={<FooterComponent />} />
          <Route path="/TeamMembers" element={<TeamMumbersComponent />} />
          <Route path="/MonthlyAttandance" element={< MonthlyAttandanceComponent/>} />
          <Route path="/ManualAttandance" element={< ManualAttandanceComponent/>} />
          <Route path="/BulkAttandance" element={< BulkAttandanceComponent/>} />
          <Route path="/LeaveApplication" element={<LeaveApplicationComponent/>} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
